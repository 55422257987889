import React from "react";
import { Link, graphql } from "gatsby";
import Seo from "../../components/Seo";
import Layout from "../../layouts/index";
import Img from "gatsby-image";
import crishmal from "../../images/crishmal-optimized.jpg";
import remotesensing from "../../images/remote-sensing-optimized.jpg";
import predictivemaintannance from "../../images/predictive-maintannance-optimized.jpg";
import devicemanagement from "../../images/device-management-compressed.jpg";
import comment from "../../images/comment.png";
import iotmet from "../../images/iot-met.jpg";

import ContactUs from "../../components/ContactUs";

const Iotanddevices = (props) => {
  return (
    <Layout bodyClass="page-services">
      <Seo
        title="IoT and Devices"
        description="iTelaSoft offers a complete range of IoT services and solutions
                encompassing IoT Solution Architecture, Device Design,
                Prototyping, Integrating, Cloud Engineering, Data Analytics and
                Management."
      />
      <div className="intro intro-banner" id="up">
        <div className="intro-banner-img">
          <Img
            fluid={props.data.bannerImg.childImageSharp.fluid}
            loading="eager"
            fadeIn={true}
            alt="Banner Image"
            className="hero-banner"
          />
        </div>
        <div className="container">
          <div className="row hero-section">
            <div className="col-12 wrap-page-title">
              <h1 className="page-title">IoT and Devices</h1>
              <hr />
            </div>
          </div>
        </div>
      </div>
      <div className="container pb-6 section-2">
        <div className="row">
          <div className="col-12 mt-4 text-center">
            <div className="sub-title-one">Embedded Device Design & IoT</div>
            <hr />
            <div className="discription">
              <p>
                We offer a complete range of IoT services and solutions
                encompassing IoT Solution Architecture, Device Design,
                Prototyping, Integrating, Cloud Engineering, Data Analytics and
                Management. Our team is highly skilled in designing special
                purpose custom hardware devices and firmware. We work with a
                variety of IoT technologies including BLE, Wi-Fi, and Lora, to
                Narrowband Mobile and leading chip makers such as Texas
                Instruments, Atmel, Qualcomm, Neul, ARM, and SimCom.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 col-md-6 col-sm-12 p-0 d-sm-block img-box">
            <img alt="Chrishmal" className="img-inno" src={crishmal} />
          </div>
          <div className="col-12 col-md-6">
            <div className="space">
              <h4>IoT Device Design</h4>
              <div className="wrap-border">
                <hr />
              </div>
              <div className="discription">
                <p>
                  Internet of Things brings your business together in
                  unprecedented ways, resulting in increased process
                  efficiencies, improved customer experience and, ultimately,
                  revenue growth. We provide a wide array of services in the IoT
                  space, from electronics design to device management. iTelaSoft
                  is working with some of the largest Telcos to create
                  innovative IoT solutions that run on GSM and Narrowband IoT
                  (CAT-NB1/CAT-M1) with different protocols, such as UDP, MQTT,
                  CoAP, and LWM2M.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-6 p-0 order-md-first order-last">
            <div className="space">
              <h4>Remote Sensing and Monitoring</h4>
              <div className="wrap-border">
                <hr />
              </div>
              <div className="discription">
                <p>
                  Avoid unnecessary on-site monitoring, resulting in reduced
                  manual processes and lowered cost of maintenance. We provide
                  bespoke solutions for various monitoring and predictive use
                  cases. With our experience in sensor integration, power
                  management, electronic signal processing, and data analytics,
                  we have been instrumental in creating cutting-edge monitoring
                  solutions for various purposes.
                </p>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 col-sm-12 p-0 d-sm-block img-box">
            <img alt="remote sensing" className="img-inno" src={remotesensing} />
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-6 col-sm-12 p-0 d-sm-block img-box">
            <img alt="predictive maintannance" className="img-inno" src={predictivemaintannance} />
          </div>
          <div className="col-12 col-md-6 p-0">
            <div className="space">
              <h4>Predictive Maintenance</h4>
              <div className="wrap-border">
                <hr />
              </div>
              <div className="discription">
                <p>
                  With ever-increasing scales of high-value machinery (vehicles,
                  precision equipment, medical support tools, etc.) the cost of
                  tracking, monitoring, and maintaining a fleet of equipment
                  becomes expensive and error prone. Our IoT solutions enable
                  sensing vitals of these equipment, reporting them and using
                  intelligent analysis to proactively predict issues and avoid
                  losses caused by unpredictable downtime and outages.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-6 p-lg-0 order-md-first order-last">
            <div className="space">
              <h4>Device Management</h4>
              <div className="wrap-border">
                <hr />
              </div>
              <div className="discription">
                <p>
                  iTelaSoft IoT Device Management Platform is a state-of-the-art
                  messaging hub with specialised device provisioning and
                  security embedded into the system. It provides a secure,
                  reliable, and convenient platform for system integrators and
                  IoT solution providers to deploy and manage systems with a
                  large number of devices.
                </p>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 col-sm-12 p-0 d-sm-block img-box">
            <img alt="device management" className="img-inno" src={devicemanagement} />
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-6 col-sm-12 p-0 d-sm-block img-box">
            <img alt="iot met" className="img-inno" src={iotmet} />
          </div>
          <div className="col-12 col-md-6 p-0 wrap-findout">
            <div className="findout">
              <div className="message">
                Find out more about how we utilise IoT services to elevate our
                clients' businesses.
              </div>
              <div className="col-12">
                {" "}
                <a
                  className="btn btn-primary"
                  href="/blog/testing-narrowband-iot-deployments/"
                >
                  Find Out More
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="row wrap-contact-us">
          <div className="container mt-7 mb-5">
            <div className="row text-center">
              <div className="col-12 col-md-3 m-auto">
                {" "}
                <img
                  className="img-fluid comment-ic"
                  alt="comment"
                  src={comment}
                />
              </div>
              <div className="col-12 col-md-6 text-center">
                <div className="title-1 pb-1">
                  Contact us for a free consultation
                </div>
                <p>Let us discuss how we can help you.</p>
              </div>
              <div className="col-12 col-md-3 m-auto">
                <a className="btn btn-primary" href="/contact/">
                  CONTACT US
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query {
    bannerImg: file(relativePath: { eq: "iot-OG-1024x535.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 4000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
export default Iotanddevices;
